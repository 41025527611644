'use client'

import { Box, Button } from '@mui/material'

interface ErrorPageProps {
  error: Error & { digest?: string }
  reset: () => void
}

const ErrorPage = ({ reset }: ErrorPageProps) => (
  <Box
    height="100%"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center">
    <h2>Something went wrong!</h2>
    <Button variant="contained" onClick={() => reset()}>
      Try again
    </Button>
  </Box>
)

export default ErrorPage
